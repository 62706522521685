.ProfileLayout {

}

.ProfileLayout-child {
    padding-top: 80px;
    padding-left: 80px;
    padding-right: 80px;
    display: flex;
    flex-direction: row;
}

.ProfileLayout-child-item{
    margin-left: 300px;
    padding-right: 30px;
    width: 100%;
}