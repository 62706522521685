.sidebar {
    background-color: var(--color-BaseWhite);
    width: 265px;
    height: 100%;
    position: fixed;
    border-right: 1px solid var(--color-Grey200);
    padding-top: 12px;
    z-index: 1000 !important;
    overflow-y: scroll;
    overflow-x: hidden;
}

.sidebar::-webkit-scrollbar {
    display: none;
}

.sidebar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
}
.sidebar-menu {
    padding-top: 30px;
}
.siderbar-username {
    display: flex;
    align-items: flex-start;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
}

.siderbar-username>span:nth-child(1) {
    font-size: 14px;
    font-family: 'PlusJakartaSansMedium';
    color: var(--color-secondary);
    opacity: 0.43;
}

.siderbar-username>span:nth-child(2) {
    font-size: 14px;
    font-family: 'PlusJakartaSansBold';
}
.sidebar-logo {
    float: left;
}
.menu-title {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.menu-title-text {
    font-family: 'PlusJakartaSansMedium';
    font-size: 12px;
    line-height: 15.12px;
    color: var(--color-secondary);
    opacity: 0.43;
    margin-left: 15px;
}
.menu-container {
   display: flex;
   align-items: flex-start;
   flex-direction: column;
   padding-top: 10px;
   padding-bottom: 20px;

}
.menu-items {
    text-decoration: none;
    width: 210px;
    height: 56px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
    padding-left: 17px;
    border-radius: 12px;
    cursor: pointer;
}
.menu-items.active {
    text-decoration: none;
    width: 210px;
    height: 56px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
    padding-left: 17px;
    border-radius: 12px;
    cursor: pointer;
    background-color: var(--color-secondary);
}
.menu-items-title-text {
    font-family: 'PlusJakartaSansMedium';
    font-size: 16px;
    line-height: 20.16px;
    color: var(--color-secondary);
    opacity: 0.43;
}
.menu-items-title-text.active {
    font-family: 'PlusJakartaSansSemiBold';
    color: var(--color-BaseWhite);
    opacity: 1;
}
.logout-container {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    gap: 12px;
    /* position: absolute; */
    bottom: 50px;
    cursor: pointer;

}
.logout-text {
    font-family: 'PlusJakartaSansMedium';
    font-size: 16px;
    line-height: 20.16px;
    color: var(--color-secondary);
    opacity: 0.43;
    align-items: center;
}
