.RoutePlan-layout {
    width: 100%;
    overflow-x: hidden;
}

.RoutePlan-sublayout {
    display: grid;
    grid-template-columns: 40% 60%;
}

.Routeplan-left {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.Routeplan-header{
    background-color: var(--color-Grey50);
    border: 1px solid var(--color-Grey200);
    border-radius: 10px;
    padding: 8px;
}

.Routeplan-header-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
}

.Routeplanheader-active{
    background-color: var(--color-primary);
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.06), 0 1px 3px rgba(16, 24, 40, 0.10);
    border-radius: 6px;
    font-family: 'InterSemiBold';
    font-size: 14px;
    color: var(--color-BaseWhite);
    width: 50%;
    
}

.Routeplanheader-inactive {
    background-color: transparent;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    font-family: 'InterSemiBold';
    font-size: 14px;
    color: var(--color-Grey500);
    width: 50%;
}

.Routeplan-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
}

.Routeplan-input-wrapper>label {
    font-family: 'InterMedium';
    font-size: 14px;
    color: var(--color-Grey700);
    line-height: 20px;
}
.Routeplan-input {
    display: flex;
    flex-direction: row;
    border: 1px solid var(--color-Grey300);
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    background-color: #FFFFFF;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 8px;
    position: relative;
}

.Routeplan-input>input {
    border: none;
    outline: none;
    width: 100%;
    font-family: 'InterMedium';
    font-size: 16px;
    color: var(--color-Grey400);
}

.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  top: 100%; /* Position the list directly below the input */
  left: 0; /* Align with the left edge of the input */
  width: 100%; /* Make the list the same width as the input */
  background: #fff;
  z-index: 1000;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
  font-family: 'InterMedium';
  font-size: 16px;
  color: var(--color-Grey400);
}

.suggestions-list li:hover {
  background-color: #f0f0f0; /* Add hover effect for better UX */
}

.Routeplan-addstop-buttonwrapper {
    padding-top: 20px;
}

.Routplan-Addstop {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid var(--color-Grey200);
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #FFFFFF;
    gap: 10px;
    font-family: 'InterSemiBold';
    font-size: 16px;
    color: var(--color-Grey400);
}

.Routeplan-otherdetail-text {
    font-family: 'InterRegular';
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    padding-top: 10px;
    text-align: start; 
    padding-bottom: 20px;
}

.Routeplan-Trasportmode-text {
  text-align: left;
  font-family: 'InterMedium';
    font-size: 14px;
    color: #344054;
    line-height: 20px;
}

.Routeplan-icons-conatiner{
  background-color: var(--color-Grey100);
   padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 14px;
    padding-right: 14px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  align-items: center;
  /* justify-content:space-around; */
  gap: 20px;
}

.Routeplan-icons-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.Routeplan-icons-wrapper>span {
  font-size: 12px;
  font-family: 'InterSemiBold';
  color: var(--color-Grey500);
}

.Routeplan-icons-inactive {
  background-color: #FFFFFF;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid var(--color-BaseWhite);
}

.Routeplan-icons-active {
  background-color: #FFFFFF;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid var(--color-primary);
}

.Routeplan-date-conatiner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    flex-wrap: wrap;
}

.Routeplan-date {
    display: flex;
    flex-direction: column;
    align-items: start;     
}

.Routeplan-date>label {
    font-family: 'InterMedium';
    font-size: 14px;
    color: var(--color-Grey700);
}

.Routeplan-datewrapper {
    border: 1px solid var(--color-Grey300);
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 14px;
}

.react-datepicker {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 500; 
  border-radius: 10px;
  border: 1px solid var(--color-Grey50);
}

.react-datepicker-time__header {
  color: var(--color-BaseWhite);
  font-weight: 700;
}

.react-datepicker__time-list {
  color: var(--color-Grey700);
}

.react-datepicker__header {
  background-color: var(--color-primary);
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.react-datepicker__day-name {
  color: var(--color-BaseWhite);
  font-weight: 700;
}

.react-datepicker__current-month {
  color: var(--color-BaseWhite);
  font-weight: 700;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
 .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
 border-radius: 0.3rem;
 background-color:var(--color-primary) !important;
 color:var(--color-BaseWhite) !important;
}

.react-datepicker__day--selected, 
.react-datepicker__day--in-selecting-range,
 .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
   .react-datepicker__month-text--in-selecting-range, 
   .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
     .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__quarter-text--in-range,
       .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color:var(--color-primary) !important;
    color: var(--color-BaseWhite) !important;
}



.Routeplan-date-picker {
    border: none;
    outline: none;
    cursor: pointer;
    max-width: 160px !important;
}

/* .react-datepicker__navigation-icon .react-datepicker__navigation-icon--next  {
  position: relative;
  
} */

.Routeplan-or-text {
    padding: 10px;
    font-family: 'InterMedium';
    font-size: 14px;
    line-height: 20px;
    color: var(--color-Grey500);
}


.Routeplan-footer {
    background-color: var(--color-Grey50);
    border-radius: 8px;
    padding: 16px;
}

.Routeplan-footer button {
     display: flex;
     flex-direction: row;
     width: 100%;
     align-items: center;
     justify-content: center;
     box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
     border: 1px solid var(--color-Grey300);
     border-radius: 8px;
     padding-top: 10px;
     padding-bottom: 10px;
     padding-left: 16px;
     padding-right: 16px;
     background-color: transparent;
     font-family: 'InterSemiBold';
     font-size: 16px;
     color: var(--color-Grey600);
  }
  
  .Routeplan-footer button img {
    margin-right: 10px;
  }

  .Routeplan-footer-download {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .Routeplan-footer-download>span {
    padding-left: 10px;
  }
  

  .Routplan-button-wrapper {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .Routeplan-calculateroute {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .non-colred-routebutton {
    border: 1px solid var(--color-Grey200);
    background-color: var(--color-Grey100);
    border-radius: 12px;
    font-size: 16px;
    font-family: 'InterSemiBold';
    color: var(--color-Grey400);
  }

  .upload-progress-wrapper {
    padding-top: 10px;
  }

  .upload-progress {
    background-color: #FFFFFF;
    border: 1px solid var(--color-Grey200);
    padding: 16px;
    border-radius: 12px;
  }


  .Routeplan-upload-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .Routeplan-upload-headerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .Routeplan-upload-filename {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    align-items: flex-start;
  }

  .Routeplan-upload-filename>span:nth-child(1) {
    font-family: 'InterMedium';
    font-size: 14px;
    color: var(--color-Grey700);
  }

  .Routeplan-upload-filename>span:nth-child(2) {
    font-family: 'InterRegular';
    font-size: 14px;
    color: var(--color-Grey600);
  }

  .Routeplan-progress-bar-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .Routeplan-progress-bar-wrapper {
    padding-left: 40px;
  }

  .Routeplan-progress-bar-wrapper>span {
    padding-left: 5px;
    font-family: 'InterMedium';
    font-size: 14px;
    color: var(--color-Grey700);
  }

  .Routeplan-editscreen {
    padding-top: 20px;
  }

  .Routeplan-edit-headrCard {
    border: 1px solid var(--color-Grey200);
    padding: 8px;
    background-color: var(--color-Grey100);
    border-radius: 20px;
  }

  .Routeplan-edit-inner{
    border-radius: 12px;
    padding: 12px;
    background-color: #FFFFFF;
  }

  .Routeplan-editheadr-text-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    padding-top: 10px;
  }

  .Routeplan-edit-headrtext {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Routeplan-edit-headrtext>span:nth-child(1){
    font-family: 'InterRegular';
    font-size: 12px;
    color: var(--color-Grey700);
  }

  .Routeplan-edit-headrtext>span:nth-child(2){
    font-family: 'InterBold';
    font-size: 20px;
    color: var(--color-Grey700);
  }

  .Routeplan-edit-middlecontent {
    padding-top: 10px;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .Routeplan-edit-datetime {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Routeplan-edit-datetime>span:nth-child(1) {
    font-family: 'InterRegular';
    font-size: 12px;
    color: var(--color-Grey700);
  }

  .Routeplan-edit-datetime>span:nth-child(2) {
    font-family: 'InterMedium';
    font-size: 14px;
    color: var(--color-Grey700);
  }

  .Routeplan-edit-crad-footer {
    padding-top: 10px;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .Routeplan-edit-footer-from {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Routeplan-edit-footer-from>span{
    font-family: 'InterMedium';
    font-size: 14px;
    color: var(--color-Grey700);
  }

  .Routeplan-edit-fromwrapper {
    border: 1px solid var(--color-Grey200);
    background-color: var(--color-Grey50);
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    font-family: 'InterRegular';
    font-size: 16px;
    color: var(--color-Grey500);
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
  }

  .Routeplan-editCard-buttonwrapper {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .Routeplan-editcard-button {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 12px;
    padding-left: 12px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid var(--color-Grey300);
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-family: 'InterSemiBold';
    font-size: 14px;
    color: var(--color-Grey400);
  }

  .Routeplan-edit-assign-conatiner {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .Routeplan-edit-assigntext {
    font-family: 'InterRegular';
    font-size: 14px;
    color: #475467;
  }

  .Routeplan-edit-searchconatiner {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 12px;
    padding-left: 12px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid var(--color-Grey300);
    background-color: #FFFFFF;
  }

  .Routeplan-edit-searchconatiner>input {
    border: none;
    outline: none;
    font-family: 'InterRegular';
    font-size: 12px;
    color: var(--color-Grey400);
  }

  .Routeplan-edit-allvehicle-Card {
    padding-top: 20px;
    max-height: 300px;
    overflow-y: scroll;
    background: transparent;
    padding-bottom: 10px;
  }

  .Routeplan-edit-allvehicle-Card::-webkit-scrollbar {
    display: none;
  }

  .Routeplan-edit-vechiclecard-wrapper {
    padding-top: 10px;
  }

  .Routeplan-edit-vechicleCrad-item {
    width: 100%;
    box-shadow: 0px 0px 33px rgba(16, 24, 40, 0.1);
    border-radius: 16px;
    background-color: var(--color-BaseWhite);
    padding: 16px;
  }

  .Routeplan-edit-vehiclecard-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .wrap-check-48 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  /* .Routeplan-editcard-checkbox>input {
    width: 20px;
    height: 20px;
  } */
  

  .wrap-check-48>span {
    font-family: 'InterSemiBold';
    font-size: 16px;
    color: var(--color-Grey700);
  }

  .wrap-check-48 input[type="checkbox"] {
    display: none;
    visibility: hidden;
  }
  
  .wrap-check-48 .cbx {
    margin: auto;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
  }
  
  .wrap-check-48 .cbx span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);
  }
  
  .wrap-check-48 .cbx span:first-child {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transform: scale(1);
    vertical-align: middle;
    border: 1px solid #9098A9;
    transition: all 0.2s ease;
  }
  
  .wrap-check-48 .cbx span:first-child svg {
    position: absolute;
    top: 3px;
    left: 2px;
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }
  
  .wrap-check-48 .cbx span:first-child:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #FF4B05;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
  }
  
  .wrap-check-48 .cbx span:last-child {
    padding-left: 8px;
  }
  
  .wrap-check-48 .cbx:hover span:first-child {
    border-color: #FF4B05;
  }
  
  .wrap-check-48 .inp-cbx:checked + .cbx span:first-child {
    background: #FF4B05;
    border-color: #FF4B05;
    animation: wave-46 0.4s ease;
  }
  
  .wrap-check-48 .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
  }
  
  .wrap-check-48 .inp-cbx:checked + .cbx span:first-child:before {
    transform: scale(3.5);
    opacity: 0;
    transition: all 0.6s ease;
  }
  
  @keyframes wave-46 {
    50% {
      transform: scale(0.9);
    }
  }

  
  .Routeplan-editvehicle-card-body {
    padding-top: 20px;
  }

  .Routeplan-editvehicle-Card-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .Routeplan-editvehicle-Card-text>span{
    font-family: 'InterRegular';
    font-size: 12px;
    color: var(--color-Grey500);
  }
  
  .Routeplan-assineddriver {
    font-family: 'InterSemiBold';
    font-size: 12px;
    color: var(--color-Grey600);
  }

  .Routeplan-edit-assign-buttons {
    padding-top: 20px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 10px;
  }

  .Routeplan-goback-button {
    border: 1px solid var(--color-Grey300);
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    padding-top: 12px;
    padding-left:18px;
    padding-bottom: 12px;
    padding-right: 18px;
    width: 50%;
    border-radius: 12px;
    background: transparent;
    font-family: 'InterSemiBold';
    font-size: 16px;
    color: var(--color-Grey600);
    height: 48px;
  }

  .Routeplan-assigntrip {
    width: 50%;
    padding-top: 12px;
    padding-left:18px;
    padding-bottom: 12px;
    padding-right: 18px;
    height: 48px;
  }


  .Routeupdcoming {
    padding-top: 20px;
  }

  .Routeupcoming-card-conatiner {
    padding-top: 20px;
  }

  .Routeupcoming-card-wrapper {
    max-height: 500px;
    overflow-y: scroll;
  }

  .Routeupcoming-card-wrapper::-webkit-scrollbar {
    display: none;
  }

  .Routeupcoming-singlecard-wrapper {
    padding-bottom: 10px;
  }


  .Routeupcoming-card {
    border: 1px solid var(--color-Grey200);
    background-color: #FFFFFF;
    padding: 16px;
    border-radius: 16px;
    
  }

  .Routeupcoming-header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .Routeupcoming-header-tripId>span {
     font-size: 14px;
     font-family: 'InterRegular';
     color: var(--color-Grey400);
  }

  .Routeupcoming-header {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .Routeupcoming-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .Routeupcoming-footer>span:nth-child(1) {
    font-family: 'InterRegular';
    font-size: 12px;
    color: var(--color-Grey400);
  }

  .Routeupcoming-footer>span:nth-child(2) {
    font-family: 'InterSemiBold';
    font-size: 18px;
    color: var(--color-Grey700);
  }


  .Route-submitmodal {
    padding: 20px;
  }

  .Route-submit-cancel {
    display: flex;
    justify-content: flex-end;
  }

  .Route-submit-header {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .Route-submit-header>span:nth-child(2) {
    padding-top: 10px;
    font-family: 'PlusJakartaSansSemiBold';
    font-size: 24px;
    color:#101828;
  }

  .Route-submit-header>span:nth-child(3) {
    padding-top: 10px;
    font-family: 'InterSemiBold';
    font-size: 16px;
    color: var(--color-Grey500);
  }

  .Route-submit-header>span:nth-child(4) {
    padding-top: 20px;
    font-family: 'InterRegular';
    font-size: 14px;
    color: #475467;
    line-height: 8px;
  }

  .Route-submit-header>span:nth-child(5) {
    font-family: 'InterRegular';
    font-size: 14px;
    color: #475467;
    padding-top: 10px;
    line-height: 8px;
  }

  .Route-submit-body {
    padding-top: 30px;
  }

  .Route-submit-cardlook {
    border: 1px solid var(--color-Grey200);
    background-color: var(--color-Grey25);
    padding: 12px;
    border-radius: 12px;
  }

  .Route-submit-assignedvehicle {
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'InterRegular';
    font-size: 14px;
    color: #475467;
  }

  .Route-submit-assigned-continer {
    max-height: 200px;
    overflow-y: scroll;

  }

  .Route-submit-assigned-continer::-webkit-scrollbar {
    display: none;
  }

  .Route-submit-assined-carderapper {
    padding-bottom: 10px;
  }

  .Route-submit-assined-card {
    border: 1px solid var(--color-Grey100);
    background-color: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(16, 24, 40, 0.05);
    padding: 16px;
    border-radius: 16px;
  }

  .Route-submit-card-vehicleNo {
    font-family: 'InterSemiBold';
    font-size: 16px;
    color: var(--color-Grey700);
  }

  .Route-submit-assined-data-wrapper {
    padding-top: 20px;
  }

  .Route-submit-assined-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .Route-submit-assined-data>span {
    font-family: 'InterRegular';
    font-size: 12px;
    color: var(--color-Grey500);
  }

  .Route-editvehicleModal {
    padding: 20px;
  }

  .Route-vehicalmodal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .Route-vehicalmodal-header>span {
    font-family: 'PlusJakartaSansSemiBold';
    font-size: 24px;
    color:#101828;
  }

  .Route-vehiclemodal-body {
    padding-top: 20px;
  }

  .Route-vehicalmodal-input {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }

  .Route-vehicalmodal-input>label {
    font-family: 'InterMedium';
    font-size: 14px;
    color:#344054;
  }

  .Route-vehicalmodal-input>input {
    border: 1px solid var(--color-Grey200);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 8px;
    font-family: 'InterMedium';
    font-size: 16px;
    color: #667085;
  }

  .Route-vehicalmodal-input>select {
    border: 1px solid var(--color-Grey200);
    background-color: #F9FAFB;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 8px;
    font-family: 'InterMedium';
    font-size: 16px;
    color: #667085;
    outline: none;
    background-color: #FFFFFF;
  }

  .Route-vehicalmodal-score {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .Route-vehicalmodal-score>span:nth-child(1) {
    font-family: 'InterRegular';
    font-size: 14px;
    color:  #667085;
  }

  .Route-vehicalmodal-score>span:nth-child(2) {
    font-family: 'InterBold';
    font-size: 18px;
    color:#667085;
  }

  .Route-vehicalmodal-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
  }

  .cardmodal-vehicle-cancel {
    width: 99px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 10px;
    border: 1px solid #D0D5DD;
    background-color: #FFFFFF;
    font-family: 'InterSemiBold';
    font-size: 16px;
    color: #344054;
    border-radius: 8px;
  }

  .cardmodal-vehicle-confirm {
    width: 99px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 10px;
  }