.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.theme-button {
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  border: 1ps solid var(--color-primary);
  background-color: var(--color-primary);
  border-radius: 8px;
  font-size: 16px;
  font-family: "InterSemiBold";
  line-height:24px;
  color: var(--color-BaseWhite);
}

.button-loader {
  width: 28px;
  height: 28px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.loader-data {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 9px solid;
  border-color: #dbdcef;
  border-right-color: #ff4b05;
  animation: spinner-d3wgkg 1s infinite linear;
}

@keyframes spinner-d3wgkg {
  to {
     transform: rotate(1turn);
  }
      
}

@keyframes donut-spin {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
}

.small-loader {
	display: inline-block;
	border: 4px solid rgb(255, 255, 255);
	border-left-color: #ff4b05;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	animation: donut-spin 1.2s linear infinite;
}

.No-data-display-conatiner {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 100px;
  font-size: 24px;
  font-family: "InterSemiBold";
  color: var(--color-Grey600);
  align-items: center;
}

.no-data-img{
  width: 30%;
  height: 25%;
  object-fit: cover;
}


.data-not-avilable {
  font-family: "InterSemiBold";
  font-size: 14px;
  color: var(--color-Grey600);
  display: flex;
  align-items: center;
  justify-content: center;
}

.filtermodal {
  padding: 20px;
  border-radius: 16px;
  border: 1px solid var(--color-BaseWhite);
}

.Addfilter {
  font-family: 'PlusJakartaSansSemiBold';
  font-size: 24px;
  color: var(--color-Grey900);
}