
.navbar-container {
    background-color: var(--color-BaseWhite);
    display: flex;
    flex-direction: row;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 100%;
    border-bottom: 1px solid var(--color-Grey200);
    padding: 0px 80px 0px 80px;
    z-index: 1000 !important;
}

.navbar-rightContent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar-dacio-logo-wrapper {
    flex-grow: 1; 
    display: flex;
    align-items: center; 
}

.dacio-logo-navbar {
    display: flex;
    float: left;
}

.navbar-padding {
    padding-right: 200px;
}

.add-manager-button-container {
    display: flex;
    flex-direction: row;
    width: 180px;
    height: 44px;
    border-radius: 8px;
    border: 1px solid var(--color-Grey300);
    margin-right: 100px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
}
.add-manager-button-icon {
    height: 20px;
    width: 20px;
}
.add-manager-button-text {
    font-family: 'InterSemiBold';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-Grey700);
}

.Sos-icon {
    padding-right: 20px;
    cursor: pointer;
    animation: glowing-shadow 1.5s infinite alternate;
}
.Sos-icon-gray{
    padding-right: 20px;
    cursor: pointer;
    animation: glowing-shadow-gray 1.5s infinite alternate;
}

@keyframes glowing-shadow-gray {
    0% {
        filter: drop-shadow(0 0 5px gray);
    }
    50% {
        filter: drop-shadow(0 0 12px gray);
    }
    100% {
        filter: drop-shadow(0 0 5px gray);
    }
}


@keyframes glowing-shadow {
    0% {
        filter: drop-shadow(0 0 5px red);
    }
    50% {
        filter: drop-shadow(0 0 12px red);
    }
    100% {
        filter: drop-shadow(0 0 5px red);
    }
}

.header-text {
    font-family: 'InterSemiBold';
    font-size: 16px;
    line-height: 24px;
}

.driver-modal {
    padding: 20px; 
 }
 
 .modal-headar{
     display: flex;
     flex-direction: row;
     justify-content: space-between;
 }
 
 .modal-headar-text {
     display: flex;
     flex-direction: column;
 }
 
 .modal-headar-text>span:nth-child(1) {
     font-family: "PlusJakartaSansSemiBold";
     font-size: 24px;
     line-height: 32px;
     color: var(--color-Grey900);
 }
 
 .modal-headar-text>span:nth-child(2) {
     font-family: "InterRegular";
     font-size: 14px;
     line-height: 20px;
     color: var(--color-Grey600);
 }
 
 .modal-mid{
     padding-top: 20px;
 }
 
 .modal-upload-container{
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
 }
 
 .modal-OR-container{
     width: 64px;
     height: 64px;
     background-color: var(--color-Grey200);
     border-radius: 200px;
     display: flex;
     align-items: center;
     justify-content: center;
 }


 .modal-OR-image{
  width: 64px;
  height: 64px;
  border-radius: 100%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
 }
 
 .modal-OR-container>span {
     font-family: "PlusJakartaSansSemiBold";
     font-size: 24px;
     line-height: 32px;
     color: var(--color-Grey600);
 }
 
 .modal-upload-button  {
     display: inline-block;
     padding: 5px;
     background-color: transparent;
     border: 1px solid var(--color-Grey300);
     box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
     border-radius: 8px;
     display: flex;
     align-items: center;
     justify-content: center;
     gap: 5px;
     width: 100px;
     height: 36px;
     font-family: "InterSemiBold";
     font-size: 14px;
     line-height: 20px;
     color: var(--color-Grey700);
     cursor: pointer;
   }
 
   .modal-upload-button input[type="file"] {
     display: none;
  
   }
 
   .modal-form-container{
     padding-top: 20px;
   }
 
   .modal-input-container {
     display: flex;
     flex-direction: column;
     padding-top: 10px;
   }
 
   .modal-input-container>label {
     font-family: "InterMedium";
     font-size: 14px;
     line-height: 20px;
     color: var(--color-Grey700);
   }
 
   .modal-input-container>input {
     border: 1px solid var(--color-Grey300);
     box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
     padding: 10px;
     border-radius: 8px;
     outline: none;
     font-family: "InterRegular";
     color: var(--color-Grey500);
     font-size: 16px;
     line-height: 24px;
   }
 
   .modal-input-container>select {
     border: 1px solid var(--color-Grey300);
     box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
     padding: 10px;
     border-radius: 8px;
     outline: none;
     font-family: "InterRegular";
     color: var(--color-Grey500);
     font-size: 16px;
     line-height: 24px;
   }
 
   .modal-checkbox {
     display: flex;
     padding-top: 10px;
     align-items: center;    
     flex-direction: row;
   }
 
   .modal-checkbox>input {
     width: 16px;
     height: 16px;
     border: 1px solid var(--color-Grey300);
     border-radius: 4px;
     background-color: var(--color-primary);
   }
 
   .modal-checkbox>a{
     font-family: "InterMedium";
     font-size: 14px;
     line-height: 20px;
     color: var(--color-Grey700);
     padding-left: 10px;
   }
 
   .modal-button-container{
     display: flex;
     flex-direction: row;
     gap: 10px;
     align-items: flex-end;
     justify-content: flex-end;
     padding-top: 10px;
   }
 
   .modal-cancel{
     width: 90px;
     height: 44px;
     border: 1px solid var(--color-Grey300);
     border-radius: 8px;
     font-family: "InterSemiBold";
     font-size: 16px;
     line-height: 24px;
     display: flex;
     align-items: center;
     justify-content: center;
   }
 
   .confirm{
     width: 99px;
     height: 44px;
     display: flex;
     align-items: center;
     justify-content: center;
   }

.select-date-range-label{
    font-size: 14px!important;
    font-weight: 500;
}
/*.start-date{*/
/*    padding: 10px 14px 10px 14px;*/
/*    width: 200px;*/
/*    height: 45px;*/
/*}*/
.modal-report-cancel{
    width: 250px;
    height: 44px;
    border: 1px solid var(--color-Grey300);
    border-radius: 8px;
    font-family: "InterSemiBold";
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.theme-button-report{
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    /*border: 1px solid var(--color-primary);*/
    background-color: var(--color-primary);
    border-radius: 8px;
    font-size: 16px;
    font-family: "InterSemiBold";
    line-height: 24px;
    color: var(--color-BaseWhite);
    border: none;
}
.confirm-report{
    width: 234px;
    height: 44px;
}
.report-heading {
    font-size: 14px;
    font-weight: 400;
}
.file-report-data{
    display: flex;
    border: 1px solid #EAECF0;
    border-radius: 8px;
    place-content: space-between;
    padding: 12px 26px 12px 12px;
}
.report-checkbox{
    margin-top: 8px;
    border: 1px solid #FF4B05;
    width: 20px;
    height: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 3px;
    /*background-color: white;*/
    cursor: pointer;
}
.report-checkbox:checked {
    background-color: #FF4B05;
    border: 1px solid #FF4B05;
}
.report-checkbox:checked::after {
    content: "✔";
    font-size: 12px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.excel-data-tab{
    display: flex;
}
.add-report-button-icon{
    width: 43px;
    height: 45px;
    margin-right: 8px;
    margin-top: 4px;
}
.report-driver-section{
    font-weight: 500;
    color: #344054;
    font-size: 17px;
}
.report-date-conatiner{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
#date-planner{
    width: 220px;
}
.heading-report-tab{
    display: flex;
    justify-content: space-between;
}
.f-btn{
    background: #847575;
}
.laoder-report{
    text-align: center;
    margin-top: 28px;
}
.Routeplan-datewrapper-one{
    border: 1px solid var(--color-Grey300);
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 14px;
    justify-content: space-between;
}
.Routeplan-date-one{
    display: flex;
    flex-direction: column;
    align-items: start;
}
.report-date-conatiner-one{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.sos-inactive-icon{
    cursor: none;
}