.ProfileSidebar {
    background-color: var(--color-BaseWhite);
    width: 260px;
    height: 100%;
    position: fixed;
}

.Profilesidebar-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
}

.data-item {
    text-decoration: none;
    font-family: 'InterSemiBold';
    font-size: 14px;
    line-height: 20px;
    padding-top: 8px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 8px;
    color: var(--color-Grey500);
}

.activeProfile {
    background-color: var(--color-Grey50);
    color: var(--color-Grey700);
}