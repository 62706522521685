.layout {
    background-color: var(--color-BaseWhite);
    /* display: flex;
    flex-direction: row; */
}
.layoutNavbarandContentContainer {
    display: flex;
    flex-direction: column;
    margin-left: 265px;    
}
.layoutNavbar {
    /* margin-left: 270px; */
    background-color: var(--color-BaseWhite);
}
.layoutContent {
    background-color: var(--color-BaseWhite);
    margin-top: 60px;
}
