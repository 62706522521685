body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --color-primary: #ff4b05;
  --color-secondary: #0d0d0d;

  /* Grey */
  --color-Grey900: #101828;
  --color-Grey800: #182230;
  --color-Grey700: #344054;
  --color-Grey600: #475467;
  --color-Grey500: #667085;
  --color-Grey400: #98a2b3;
  --color-Grey300: #d0d5dd;
  --color-Grey200: #eaecf0;
  --color-Grey100: #f2f4f7;
  --color-Grey50: #f9fafb;
  --color-Grey25: #fcfcfd;
  --color-GreyLight: #f9f9f9;
  --color-FoundationDarkLight: #e7e7e7;
  --color-BgGrey: #cfcbdc;

  /* Orange */
  --color-Brand25: #fff8f3;
  --color-Brand50: #fff5ed;
  --color-Brand200: #ffcca8;
  --color-Brand700: #bf3804;
  --color-Brand500: #ff4b05;
  --color-orange700: #c72607;
  --color-orangeDashboard: #f79009;
  --color-orangePrimary: #ffe7d4;

  /* White */
  --color-Brand200: #ffede6;
  --color-BaseWhite: #ffffff;

  /* Black */
  --color-FoundationBaseBlack: #0d0d0d;
  --color-BaseBlack: #000000;

  /* Blue */
  --color-Blue: #6223e3;
  --color-FoundationBlue: #106efa;
  --color-Blue500: #2e90fa;
  --color-BlueLight400: #36bffa;
  --color-DarkBlue: #7a5af8;
  --color-Blue50: #eff8ff;
  --color-Blue200: #b2ddff;
  --color-Blue700: #175cd3;

  /* Yellow */
  --color-Warning400: #fdb022;

  /* Green */
  --color-Green: #17b26a;
  --color-Success700: #067647;
  --color-Success200: #abefc6;
  --color-Success50: #ecfdf3;
  --color-Success500: #17b26a;

  /* Red */
  --color-Error500: #f04438;
  --color-Error400: #f97066;
  --color-Error50: #fef3f2;
  --color-Error200: #fecdca;
  --color-Error700: #b42318;
  --color-Error900: #7a2e0e;

  /* Purple */
  --color-Fuchsia500: #d444f1;

  /* indigo */
  --color-indigo200: #c7d7fe;
  --color-indigo50: #eef4ff;
  --color-indigo700: #3538cd;
  /* LiearGradient */
  --color-Gradient09: #a6c0fe;
  --color-Gradient08: #ffeaf6;

  --color-purple500: #7a5af8;
  --color-orange600: #f03806;
  --color-yellow800: #f3a108;
  --color-pink900: #832727;
  --color-indigo800: #1e4580;
  --color-gray5008e: #9d9d9d;
  --color-gray850: #0b151b;
  --color-cyan800: #28748c;
  --color-green: #05ebd5;
  --color-green90001: #186622;
  --color-indigo300: #737bc1;
  --color-error200: #fecdca;
  --color-power: #7fa1b0;
  --color-driver-drose: #9b89b3;
  --color-distracted: #d5cabd;

  --color-gps: #ff5733;
  --color-smoking: #8e44ad;
  --color-calling: #3498db;
  --color-Yawn: #ffc300;
  --color-Dozing: #c70039;
  --color-Distract: #e74c3c;
  --color-misingface: #900c3f;
  --color-harshacceleration: #f39c12;
  --color-overspeed: #d35400;
  --color-crash: #e67e22;
  --color-Lanedeparture: #e74c3c;
  --color-Forwardcollision: #c0392b;
}

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~maplibre-gl/dist/maplibre-gl.css';

/* Font Families */

/* Inter Font Family */
@font-face {
  font-family: 'InterBold';
  src: local('InterBold'), url('./Fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'InterMedium';
  src: local('InterMedium'), url('./Fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'InterRegular';
  src: local('InterRegular'), url('./Fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'InterSemiBold';
  src: local('InterSemiBold'), url('./Fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

/* Plus Jakarta Sans Font Family */

@font-face {
  font-family: 'PlusJakartaSansBold';
  src: local('PlusJakartaSansBold'),
    url('./Fonts/PlusJakartaSans/PlusJakartaSans-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'PlusJakartaSansMedium';
  src: local('PlusJakartaSansMedium'),
    url('./Fonts/PlusJakartaSans/PlusJakartaSans-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'PlusJakartaSansRegular';
  src: local('PlusJakartaSansRegular'),
    url('./Fonts/PlusJakartaSans/PlusJakartaSans-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'PlusJakartaSansSemiBold';
  src: local('PlusJakartaSansSemiBold'),
    url('./Fonts/PlusJakartaSans/PlusJakartaSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

/* Gillory Font Family */
@font-face {
  font-family: 'GillroyBold';
  src: local('GillroyBold'), url('./Fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'GillroyMedium';
  src: local('GillroyMedium'), url('./Fonts/Gilroy/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'GillroyRegular';
  src: local('GillroyRegular'), url('./Fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'GillroySemiBold';
  src: local('GillroySemiBold'), url('./Fonts/Gilroy/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
